<template>
  <div class="all-rule-list">
    <el-form :inline="true" style="margin-bottom: -24px; !important" class="form-content">
      <el-form-item label="问卷名称">
        <el-input placeholder="输入关键字查询" v-model="questionName" style="width: 300px;">
          <el-button slot="append" @click="getDataList(undefined, undefined, lasttableModel)" icon="el-icon-search"></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="是否发布">
        <el-select v-model="isPublish" clearable>
          <el-option :value="1" label="是">是</el-option>
          <el-option :value="2" label="否">否</el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-select v-model="isUse" clearable>
          <el-option :value="1" label="是">是</el-option>
          <el-option :value="2" label="否">否</el-option>
        </el-select>
      </el-form-item>
      <el-form-item style="padding-left:20px;">
        <el-button type="primary" @click="getDataList()">搜索</el-button>
        <el-button type="info" @click="resetValue">重置</el-button>
      </el-form-item>
    </el-form>
    <el-divider ></el-divider>
    <el-container style="padding: 1px;">
      <el-aside width="300px">
        <OrgTree @refreshDataList="getDataList"></OrgTree>
      </el-aside>
      <el-main>
        <div class="search-list">
          <el-button type="primary" @click="addSubject()">添加</el-button>
          <el-button
            type="primary"
            @click="disableClick(1)"
            :disabled="dataListSelections.length <= 0"
          >发布</el-button>
          <el-button
            type="primary"
            @click="disableClick(2)"
            :disabled="dataListSelections.length <= 0"
          >开启</el-button>
          <el-button
            type="primary"
            @click="disableClick(3)"
            :disabled="dataListSelections.length <= 0"
          >禁用</el-button>
          <el-button
            type="danger"
            @click="disableClick(4)"
            :disabled="dataListSelections.length <= 0"
          >删除</el-button>
        </div>
        <el-table
          :data="dataList"
          v-loading="dataListLoading"
          @selection-change="selectionChangeHandle"
          style="width: 100%;margin-top: 0;"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column prop="tableName" label="问卷名称">
            <template slot-scope="scope">
				<div style="cursor: pointer;white-space:normal;font-size:14px;user-select: unset;color: #0476F5;"> {{
                scope.row.tableName
                }}</div>
             <!-- <el-button
                type="text"
                size="small"
                @click="handleViewInfo(scope.row)"
                style="white-space:normal;font-size:14px;user-select: unset;"
              >
                {{
                scope.row.tableName
                }}
              </el-button> -->
            </template>
          </el-table-column>
          <!-- <el-table-column prop="createTime" label="创建时间"></el-table-column> -->
          <el-table-column  width="120" label="排序权重">
			  <template slot-scope="scope">
			  	<div style="padding: 11px;">{{scope.row.orderWeight}}</div>
			  </template>
		  </el-table-column>
          <el-table-column prop="publishStatusName" width="120" label="是否发布">
            <template slot-scope="scope">{{scope.row.publishStatus == 1?'是':'否'}}</template>
          </el-table-column>
          <el-table-column prop="isUseName" width="120" label="是否启用">
            <template slot-scope="scope">{{scope.row.isUse == 1?'是':'否'}}</template>
          </el-table-column>
          <el-table-column fixed="right" header-align="right" align="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="text" @click="copyAdd(scope.row)" title="复制">
                <img src="~@/assets/img/dataCollect/icon-1.png" style="width:14px;">
              </el-button>
              <el-button type="text" @click="handlePreview(scope.row)" title="预览">
                <img src="~@/assets/img/dataCollect/icon-2.png" style="width:14px;">
              </el-button>
              <el-button type="text" @click="handleData(scope.row)" title="数据">
                <img src="~@/assets/img/dataCollect/icon-3.png" style="width:14px;">
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
        <el-dialog
          title="复制问卷"
          :close-on-click-modal="false"
          :visible.sync="copyDialog"
          width="800px"
        >
          <el-form
            :model="dataForm"
            :rules="rules"
            ref="dataForm"
            label-width="120px"
            class="demo-dataForm"
            style="width: 80%;margin:0 auto;"
          >
            <el-form-item label="问卷分类" prop="tableModel">
              <el-cascader
                v-model="array"
                :options="menuList"
                :props="defaultProps"
                ref="elcascader"
                @change="handleChange"
                popper-class="theder"
                style="width:100%;"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="表单名称" prop="tableName">
              <el-input placeholder="请输入名称" v-model="dataForm.tableName"></el-input>
            </el-form-item>
       <!--     <el-form-item label="表单权限" prop="authorityType">
              <el-select v-model="dataForm.authorityType" style="width:100%;" clearable>
                <el-option :value="1" label="团队内共享">团队内共享</el-option>
                <el-option :value="2" label="仅自己使用">仅自己使用</el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="表单权重">
              <el-input placeholder="默认为0，数字越大，排序越靠前" type="number" v-model="dataForm.orderWeight"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="submitSubject">确定</el-button>
            <el-button @click="copyDialog = false">取消</el-button>
          </span>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import OrgTree from "./tree/tree";

import qs from "qs";
import QRCode from "qrcodejs2";
import Clipboard from "clipboard";
export default {
  components: {
    OrgTree,
  },
  data() {
    return {
      questionName: "", //搜索内容
      isPublish: "", //是否发布
      isUse: "", //是否启用
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
	  lastType: "",
      searchContent: "",
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      userId: "",
      unionId: "",
      copyDialog: false,
      url: "",
      lasttableModel: "",
      contentTypeSec: "",
      contentType: "",
      dataForm: {
        hospitalId: "", //医院id
   
        tableName: "", //表单名称
        tableBelongs: 1, //表单权限
        orderWeight: "", //权重
        tableType: "",
        tableModel: "",
        authorityType: 1,
      },
      rules: {
        tableName: [
          { required: true, message: "请输入表单名称", trigger: "blur" },
        ],
        authorityType: [
          {
            required: true,
            message: "请选择表单权限",
            trigger: "change",
          },
        ],
        tableModel: [
          { required: true, message: "请选择问卷分类", trigger: "change" },
        ],
      },
      array: [],
      menuList: [],
      defaultProps: {
        children: "list",
        label: "name",
        expandTrigger: "hover",
        checkStrictly: true,
        emitPath: true,
      },
      contentType: "",
      contentTypeSec: "",
      flag: 1,
    };
  },
  mounted() {
    if (localStorage.userInfo) {
      let userInfo = JSON.parse(localStorage.userInfo);
      this.userId = userInfo.id;
      this.unionId = userInfo.doctorUnionId;
    }
    this.getDataList();
  },
  methods: {
    handleChange(node) {
      console.log(node);
      if (node.length) {
        this.dataForm.tableModel = node[0];
        if (node[1]) {
          this.dataForm.tableType = node[1];
        } else {
          this.dataForm.tableType = "";
        }
        this.array = node;
      }
    },
    // 获取字典
    async getDictList() {
      let _this = this;
      let teamsId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      const { data } = await this.$httpAes({
        url: _this.$httpAes.adornUrl("/dict/getSysDicts?type=" + "表单类别"),
        method: "post",
		data: {
          stringParam1:'表单类别',
          pageSize: 999,
        },
		contentType:'json',
		headers: {
			'Content-Type': 'application/json',
		   				
		},
    
      });

      if (data.status) {
        _this.menuList = data.data;
        this.changeData(_this.menuList);
        if (this.dataForm.tableModel) {
          this.array[0] = parseInt(this.dataForm.tableModel);
        }
        if (this.dataForm.tableType) {
          this.array[1] = this.dataForm.tableType;
        }
      }
    },

    changeData(arr) {
      for (var i = 0; i < arr.length; i++) {
        arr[i].list = arr[i].map.list;
        if (arr[i].map.list != null && arr[i].map.list.length != 0) {
          this.changeData(arr[i].map.list);
        }
        if (arr[i].map.list.length < 1) {
          arr[i].list = undefined;
        }
      }
    },
    resetValue() {
      this.questionName = "";
      this.isPublish = "";
      this.isUse = "";
    },
    getDataList(d, n, lasttableModel) {
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let userInfo = {};
      if (localStorage.userInfo) {
        userInfo = JSON.parse(localStorage.userInfo);
      }
      let type = "";
      let typeSec = "";
      let tableBelongs = "";
      if (d) {
		   this.pageIndex =1
        if (d.isEdit == false) {
          tableBelongs = 1;
        }
        if (d.type == "表单类别") {
          type = d.value;
          typeSec = "";
		  this.lastType ="";
          this.contentType = d.value;
          this.contentTypeSec = "";
        } else {
          type = n.parent.data.value;
          typeSec = d.value;
		  this.lastType = typeSec
          this.contentType = n.parent.data.value;
          this.contentTypeSec = d.value;
        }
      }else{
		  typeSec =  this.lastType 
	  }

      if (lasttableModel) {
        type = lasttableModel;
      } else {
        this.lasttableModel = type;
      }
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/vfTableInfo/list"),
        method: "post",
		data: {
          pageSize: this.pageSize,
          pageNo: this.pageIndex,
          stringParam1: this.questionName,
          intParam4: this.isPublish,
          intParam5: this.isUse,
          intParam1: 1,
          intParam3: typeSec,
          intParam2: type,
     
        },
		contentType:'json',
		headers: {
			'Content-Type': 'application/json',
		   				
		},
       
      }).then(({ data }) => {
        if (data.status) {
          this.dataList = data.data;
          this.totalPage = data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    handelChange(val) {
      this.getDataList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList(undefined, undefined, this.lasttableModel);
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList(undefined, undefined, this.lasttableModel);
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    //禁用
    disableClick(status) {
      var that = this;
      let ids = that.dataListSelections.map((item) => {
        return item.id;
      });
      let statusName = "";
      if (status == 1) {
        statusName = "发布成功";
      } else if (status == 2) {
        statusName = "启用成功";
      } else if (status == 3) {
        statusName = "禁用成功";
      } else if (status == 4) {
        statusName = "删除成功";
      }
      if (status == 4) {
        this.$confirm(`确定进行删除操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonClass: "btn-custom-cancel",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that
              .$httpAes({
                url: that.$httpAes.adornUrl("/vfTableInfo/operate"),
                method: "post",
				contentType:'json',
				headers: {
					'Content-Type': 'application/json',
				   				
				},
                data: {
                  stringParam1: ids.join(","),
                  intParam1: status,
                },
              })
              .then(({ data }) => {
                if (data.status) {
                  that.$message({
                    message: statusName,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      that.getDataList();
                    },
                  });
                } else {
                  that.$message({
                    message: data.msg,
                    type: "error",
                    duration: 1500,
                    onClose: () => {},
                  });
                }
              });
          })
          .catch(() => {});
      } else {
        that
          .$httpAes({
            url: that.$httpAes.adornUrl("/vfTableInfo/operate"),
            method: "post",
			contentType:'json',
			headers: {
				'Content-Type': 'application/json',
			   				
			},
            data: {
              stringParam1: ids.join(","),
              intParam1: status,
            },
          })
          .then(({ data }) => {
            if (data.status) {
              that.$message({
                message: statusName,
                type: "success",
                duration: 1500,
                onClose: () => {
                  that.getDataList();
                },
              });
            } else {
              that.$message({
                message: data.msg,
                type: "error",
                duration: 1500,
                onClose: () => {},
              });
            }
          });
      }
    },

    addSubject() {
      this.$router.push({
        path: "/dataCollect/add",
        query: {
          contentType: this.contentType,
          contentTypeSec: this.contentTypeSec,
        },
      });
    },
    copyAdd(row) {
      this.copyDialog = true;
      this.getDictList();
      this.dataForm = {
        hospitalId: row.hospitalId, //医院id
   
        tableName: row.tableName, //表单名称
        tableBelongs: 1, //表单权限
        orderWeight: row.orderWeight, //权重
        tableType: row.tableType,
        tableModel: row.tableModel,
        authorityType: row.authorityType,
        tableContent: row.tableContent,
        publishStatus: row.publishStatus,
        isUse: row.isUse,
        tableRule: row.tableRule,
        scoreSetting: row.scoreSetting,
      };
    },
    //提交指标库
    submitSubject() {
      let _this = this;
      if (localStorage.currentTeam) {
        _this.dataForm.teamId = JSON.parse(localStorage.currentTeam).id;
      }
      if (localStorage.userInfo) {
        this.dataForm.hospitalId = JSON.parse(localStorage.userInfo).deptPid;
      }

      if (_this.unionId) {
        _this.dataForm.unionId = _this.unionId;
      }
      let params = JSON.parse(JSON.stringify(_this.dataForm));
      if (_this.dataForm.orderWeight) {
        params.orderWeight = _this.dataForm.orderWeight;
      } else {
        params.orderWeight = 0;
      }
      // console.log(params);
      // return
      _this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (_this.flag == 1) {
            _this.flag = 0;
            _this
              .$httpAes({
                url: _this.$httpAes.adornUrl("/vfTableInfo/add"),
                method: "post",
				contentType:'json',
				headers: {
					'Content-Type': 'application/json',
				   				
				},
                data: params,
              })
              .then((data) => {
                if (data.data.status) {
                  _this.$message({
                    message: data.data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.flag = 1;
                      _this.copyDialog = false;
                      _this.getDataList();
                    },
                  });
                } else {
                  _this.flag = 1;
                  _this.$message({
                    message: data.data.msg,
                    type: "error",
                    duration: 1500,
                    onClose: () => {},
                  });
                }
              });
          }
        }
      });
    },
    handleViewEdit(index, row) {
      this.$router.push({
        path: "/dataCollect/add",
        query: {
          id: row.id,
          type: 2,
        },
      });
    },
    handleViewInfo(row) {
      this.$router.push({
        path: "/dataCollect/info",
        query: {
          id: row.id,
        },
      });
    },
    // 预览
    handlePreview(row) {
      this.$router.push({
        path: "/dataCollect/preview",
        query: {
          id: row.id,
        },
      });
    },
    // 数据
    handleData(row) {
      this.$router.push({
        path: "/dataCollect/info",
        query: {
          id: row.id,
          activeName: 3,
        },
      });
    },
  },
};
</script>

<style lang="scss" >
	.el-table th.el-table__cell > .cell {
	    padding: 12px 16px;
	}
	.el-table__cell{
		 padding: 0 !important;
	}
	.qs-content{
		
		
		// .el-table head{
		// 	 padding: 12px 0 !important;
		// }
	}
.theder {
  .el-cascader-panel {
    .el-cascader-menu {
      .el-radio {
        height: 100%;
        width: 150px;
        opacity: 0;
        position: absolute;
        // z-index: 10;
        .el-radio__input {
          .el-radio__inner {
            border: 0px;
          }
        }
        .el-radio__input.is-checked {
          .el-radio__inner {
            background: none;
          }
        }
      }
    }
  }
}
.all-rule-list {
  .el-main {
    padding: 0 20px;
  }
  .search-top {
    padding-bottom: 20px;
    overflow: hidden;

    .search-btn {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .share-box {
    line-height: 3em;
  }
  .copy-box {
    display: flex;
    justify-content: space-between;
    .qrcode {
      width: 200px;
      height: 200px;
    }
  }
  .el-button--text {
    white-space: normal;
  }
}
</style>